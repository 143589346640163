import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import utils from 'utils';

type Props = {
  data: any
  title: string
  color?: string
}

const GenericTableCenterChart: FC<Props> = ({ data, title, color = '#014380' }) => {
  return (
    <Stack
      { ...utils.stack.flex.col('flex-start', 'flex-start') }
      sx={{ width: '100%', maxWidth: '450px', minWidth: '450px' }}
    >
      <Stack flexDirection={'row'} alignItems="center" > 
        <Typography variant='h3'>{ title }</Typography>
        {title ==='CONFORMAÇÃO' &&<Typography fontSize={12} marginLeft={1}> ACC: {data?.ACC?.value} </Typography>}

      </Stack>
      {
        data &&
        Object.entries(data)
          .map(([ key, val ]: [string, any], i, arr) => {
            const treatedVal = (+(val.value || '0').replaceAll(',', '.')) || 0;
            const baseVal = Math.abs(val.base); 
            const absTreatedVal = Math.abs(treatedVal);

            const baseMin = ((val.base-val.min)/2) + val.min;
            const baseMax = ((val.max-val.base)/2) + val.base;

            const totalUnits = 5;
            const unitSize = 1;
            const half = Math.floor(totalUnits / 2);
            const max = Math.abs(val.max);
            const steps = [ val.min, baseMin, val.base, baseMax, val.max ];


            const totalPercentage = (val.max-val.min);

           


            const rawPercentage = Math.abs(100 * (treatedVal - baseVal) / (max - baseVal));
            const mappedPercentage = (rawPercentage * 50) / 100;
            const limitedPercentage = Math.min(mappedPercentage, 50);
            const remaining = mappedPercentage > 50;
            const positive = treatedVal >= baseVal;

            const leftRawPercentage = Math.max(treatedVal / max * 100, 0);
            const percentageVal = Math.min(leftRawPercentage, 100);
            const positiveLeft = leftRawPercentage > 100;
            const negativeLeft = leftRawPercentage <= 0;   



            return (
              <>
                {
                  key==='ACC' ? <></>
                    :
                    val.middle ? (
                      <Stack
                        { ...utils.stack.flex.row('space-between', 'center') }
                        spacing={ 3 }
                        sx={{
                          width: '100%',
                          minHeight: '44px',
                          paddingY: '8px',
                          borderBottom: `1px solid ${i === arr.length - 1 ? 'transparent' : '#999'}`,
                        }}
                      >
                        <Stack
                          { ...utils.stack.flex.row('space-between', 'center') }
                          sx={{ width: '50%' }}
                        >
                          <Typography sx={{ whiteSpace: 'wrap', marginRight: '12px', fontSize: '12px !important' }}>
                            { key }
                          </Typography>
                          <Typography sx={{ fontSize: '12px !important' }}>{ val.value }</Typography>
                        </Stack>
                        <Stack
                          { ...utils.stack.flex.row('center', 'center') }
                          sx={{
                            minWidth: '45%',
                            maxWidth: '45%',
                            position: 'relative',
                          }}
                        >
                          <Box sx={{
                            width: `${limitedPercentage || '1'}%`,
                            background: color,
                            height: '12px',
                            transform: `translateX(${positive ? '' : '-'}50%)`
                          }}>
                            {
                              remaining &&
                      <Box sx={{
                        width: '0px',
                        height: '0px',
                        borderTop: '6px solid transparent',
                        borderBottom: '6px solid transparent',
                        ...(
                          positive
                            ? { borderLeft: `6px solid ${color}`, right: 0 }
                            : { borderRight: `6px solid ${color}`, left: 0 }
                        ),
                        position: 'absolute',
                        transform: `translateX(${positive ? '' : '-'}100%)`,
                        
                      }}></Box>
                            }
                          </Box>
                          <Box sx={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            left: 0,
                            top: 0,
                          }}>
                            {
                              steps.map((n, j) => (
                                <Box sx={{
                                  position: 'absolute',
                                  width: '1px',
                                  height: '18px',
                                  transform: 'translateY(-25%)',
                                  background: '#999',
                                  marginLeft: `${25 * j}%`,
                                  '&::after': {
                                    width: 'min-content',
                                    content: `"${n}"`,
                                    display: 'block',
                                    color: '#999',
                                    fontSize: '11px',
                                    transform: 'translate(-50%, 110%)'                   
                                  }
                                }}></Box>
                              ))
                            }
                          </Box>
                        </Stack>
                      </Stack>
                    ):(
                      <Stack
                        { ...utils.stack.flex.row('space-between', 'center') }
                        sx={{
                          width: '100%',
                          borderBottom: `1px solid ${i === arr.length - 1 ? 'transparent' : '#333'}`,
                          minHeight: '32px',
                          paddingY: '8px',
                        }}
                        spacing={3}
                        key={key}
                      >
                        <Stack
                          { ...utils.stack.flex.row('space-between', 'center') }
                          sx={ utils.sx.fw } 
                          spacing={ 2 }
                        >
                          <Typography sx={{ fontSize: '12px !important' }}>{ key }</Typography>
                          <Typography sx={{ fontSize: '12px !important' }}>{ treatedVal }</Typography>
                        </Stack>
                        <Box sx={{
                          width: '45%',
                          minWidth: '45%',
                          ...utils.sx.flex.row('flex-start', 'center'),
                          position: 'relative'
                        }}>
                          <Box sx={{
                            width: `${percentageVal.toFixed(2)}%`,
                            height: '12px',
                            background: color,
                            position: 'absolute',
                            left: 0
                          }}>
                            {
                              (positiveLeft || negativeLeft) &&
                    <Box sx={{
                      width: '0px',
                      height: '0px',
                      borderTop: '6px solid transparent',
                      borderBottom: '6px solid transparent',
                      ...(
                        positiveLeft
                          ? { borderLeft: `6px solid ${color}`, right: 0 }
                          : { borderRight: `6px solid ${color}`, left: 0 }
                      ),
                      position: 'absolute',
                      transform: `translateX(${positiveLeft ? '' : '-'}100%)`,
                      
                    }}></Box>
                            }
                          </Box>
                          {
                            steps.map((n, i) => (
                              <Box key={i} sx={{
                                position: 'absolute',
                                width: '2px',
                                height: '17px',
                                background: '#999',
                                marginLeft: `${25 * i}%`,
                                '&::after': {
                                  width: 'min-content',
                                  content: `"${n}"`,
                                  display: 'block',
                                  color: '#999',
                                  fontSize: '12px',
                                  transform: 'translate(-50%, 75%)'                   
                                }
                              }}></Box>
                            ))
                          }
                        </Box>
                      </Stack>
                    )}
              
              </>
            );
          })
      }
    </Stack>
  );
};

export default GenericTableCenterChart;