import { Button, Checkbox, Grid, List, ListItemButton, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { BreedCard, ButtonWithIcon, Space } from 'components';
import { actions, Store } from 'rdx';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Breed, Bull } from 'types';
import utils from 'utils';
import { getStorage, ref, getBlob } from 'firebase/storage';
import axios from 'axios';
import React from 'react';


const filterBreeds = (breeds: Breed[], scope: Store['scope']) => {
  return breeds.filter(breed => utils.segmentFromBullOrBreed(breed) === scope.segment);
};

const Breeds: FC = () => {
  
  const { scope, items } = useSelector((store: Store) => store);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (scope.stamps != null && scope.stamps.length > 0) {
    dispatch(actions.scope.changeStamps(new Array<string>()));
    dispatch(actions.scope.changeNationality('all'));
  }

  const [ catalogExists, setCatalogExists ] = useState(false);
  const [ loading, setLoading, ] = useState(false);
  const [ catalogData, setCatalogData ] = useState({ updated: '' });
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSingleSelection = (v: boolean, bull: Bull | number) => {
    const index = typeof bull === 'number'
      ? bull
      : items.bulls.findIndex(b => b.CRVcode === bull.CRVcode);
    dispatch(actions.items[v ? 'selectBull' : 'unselectBull'](index));
    if (!v) {
      dispatch(actions.items.removeAllSelected({
        type: 'segment',
        key: scope.segment
      }));
    }
  };

  const onSelectAll = useCallback((v: boolean) => {
    items.scopedBulls.forEach(index => handleSingleSelection(v, index));
    dispatch(actions.items[v ? 'pushAllSelected' : 'removeAllSelected']({
      type: 'segment',
      key: scope.segment
    }));
  }, [ handleSingleSelection ]);

  const checkCatalogDownload = async () => {
    try {
      
      const response = await axios.get(`https://firebasestorage.googleapis.com/v0/b/crv-lagoa.appspot.com/o/${SEGMENT_URL}%2Fcatalog%2Fatual.pdf`);
      setCatalogData(response.data);
      setCatalogExists(true);
    } catch (error) {
      setCatalogExists(false);
    }
  };

  useEffect(()=>{
    checkCatalogDownload();
  },[]);

  const SEGMENT_URL=scope.segment==='el'?'leite_europeu':scope.segment==='zl'?'leite_zebu': scope.segment==='ec'?'corte_europeu':'corte_zebu';
  const onCLickDownloadBtn = async (language: string) => {

    handleClose();

    if (language == null || language == '' || language == 'pt') {
      language = '';
    } else {
      language = '_' + language;
    }

    const storage = getStorage();
    try {
      setLoading(true);
      const blob = await getBlob(ref(storage, SEGMENT_URL+'/catalog/atual' + language + '.pdf'));
      const url = window.URL.createObjectURL(new Blob([ blob ]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', SEGMENT_URL + language +'.pdf');
      document.body.appendChild(link);
      link.click();
      if (!link.parentNode) return;
      link.parentNode.removeChild(link);

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack { ...utils.stack.flex.col('flex-start', 'flex-start') }>
      <Stack 
        alignItems={'center'}
        flex={1}
        width="100%"
      >
        <ButtonWithIcon
          iconURL="/icons/cloud-download-white.svg"
          disabled={loading || !catalogExists}
          onClick={handleClick}
          loading={loading}
        >
          Catálogo completo
        </ButtonWithIcon>
        {catalogExists  ? <p>Data: {new Date(catalogData.updated).toLocaleDateString('pt-BR',{
          month:'numeric',
          year:'numeric'
        })}</p> : <p>Catálogo indisponível</p>}

        <Menu
          id="lock-menu"
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }} 
          anchorEl={anchorEl}
          open={open}      
          onClose={handleClose}
          PaperProps={{  
            style: {  
              width: 196,  
            },  
          }}
        >
          <MenuItem
            onClick={() => onCLickDownloadBtn('pt')}
          >
            Português
          </MenuItem>
          <MenuItem
            key={1}
            onClick={() => onCLickDownloadBtn('en')}
          >
            English
          </MenuItem>
          <MenuItem
            key={1}
            onClick={() => onCLickDownloadBtn('es')}
          >
            Español
          </MenuItem>
        </Menu>

      </Stack>
      <Stack
        { ...utils.stack.flex.row('flex-start', 'center') }
        sx={ utils.sx.fw }
        spacing={ 1 }
      >
        <Checkbox
          checked={ utils.isAllSelected(items.allSelected, scope, 'segment') }
          sx={(theme) => ({ color: theme.palette.primary.main, padding: '0px' })}
          onChange={(_, v) => onSelectAll(v)}
        />
        <Typography> Selecionar todos ({ items.scopedBulls.length }) </Typography>
      </Stack>
      <Space size={ 3 } />
      <Grid
        container
        { ...utils.stack.flex.row('flex-start', 'flex-start') }
        sx={ utils.sx.fw }
        rowSpacing={ 4 }
        columnSpacing={ 4 }
      >
        {
          filterBreeds(items.breeds, scope).map((breed, i)=> (
            <Grid
              justifyContent='center'
              item
              xs={ 12 }
              md={ 6 }
              lg={ 4 }
              key={`${breed.name}${i}`}
            >
              <BreedCard
                breed={ breed }
                onClick={ utils.location.fromBreed(navigate, dispatch) }
              />
            </Grid>
          ))
        }
      </Grid>
    </Stack>
  );
};

export default Breeds;