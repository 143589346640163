import { AxiosResponse } from 'axios';
import http from 'services/http';
import { Breed, Bull } from 'types';

export type GetAllResponse = {
  bulls: Bull[],
  breedsProofs: Breed[]
}

const getAll = async () => http.get<any, AxiosResponse<GetAllResponse>>('/bull/find');

const findByCrvCodes = async (CRVcodes: string[]) => {
  const params = { CRVcodes };

  return http.get<any, AxiosResponse<GetAllResponse>>('/bull/find', { params });
};

const bulls = {
  getAll,
  findByCrvCodes,
};

export default bulls;